import { Box, Heading, Container, Text, Stack } from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";
import ProfileArrayES from "./ProfileArrayES";
import Typical from 'react-typical';
function Header({
  color,
  language,
  onLanguageChange
}) {
  const profileES = ProfileArrayES();
  const profile = ProfileArray();
  const headerName = language === "es" ? profileES.headerName : profile.headerName;
  const headerRole = language === "es" ? profileES.headerRole : profile.headerRole;
  return <>
      <Container maxW={"5xl"} id="hero">
        <Stack as={Box} textAlign={"center"} spacing={{
        base: 8,
        md: 14
      }} pb={{
        base: 20,
        md: 36
      }} pt={{
        base: 36,
        md: 52
      }}>
          <Heading fontWeight={600} fontSize={{
          base: "2xl",
          sm: "4xl",
          md: "6xl"
        }} lineHeight={"110%"}>
            <Typical steps={[headerName, 1000]} loop={1} wrapper="p" />
            <br />
            <Text as={"span"} color={`${color}.400`}>
              <Typical steps={[headerRole, 1000]} loop={1} wrapper="p" />
            </Text>
          </Heading>
        </Stack>
      </Container>
    </>;
}
export default Header;