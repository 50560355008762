import { useState, useEffect } from "react";
const parseProjectsES = mdContent => {
  const projectsES = [];
  const lines = mdContent.split("\n");
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line.startsWith("## ")) {
      const name = line.substr(3).trim();
      const description = lines[++i].trim();
      const tags = lines[++i].split(":")[1].trim();
      let image = "";
      const badges = [];
      const buttons = [];
      while (lines[++i] && !lines[i].startsWith("- Badges:") && !lines[i].startsWith("- Buttons:")) {
        if (lines[i].startsWith("- ![") && lines[i].includes("](")) {
          // Extraer la URL de la imagen si existe
          image = lines[i].match(/!\[(.*)\]\((.*)\)/)[2].trim();
        }
      }
      while (lines[++i] && lines[i].startsWith("  - ")) {
        const badgeLine = lines[i].substr(4).split("[");
        const badgeName = badgeLine[0].trim();
        const badgeColor = badgeLine[1].split("]")[0].trim();
        badges.push({
          text: badgeName,
          colorScheme: badgeColor
        });
      }
      while (lines[++i] && lines[i].startsWith("  - ")) {
        const buttonLine = lines[i].substr(4).split("[");
        const buttonText = buttonLine[0].trim();
        const buttonHref = buttonLine[1].split("]")[0].trim();
        buttons.push({
          text: buttonText,
          href: buttonHref
        });
      }
      projectsES.push({
        name,
        description,
        tags: [tags],
        image,
        badges,
        buttons
      });
    }
  }
  return projectsES;
};
const ProjectsArrayES = () => {
  const [ProjectsES, setProjectsES] = useState([]);
  useEffect(() => {
    fetch("/content/ProjectsES.md").then(response => {
      if (!response.ok) {
        throw new Error("Failed to fetch markdown content");
      }
      return response.text();
    }).then(mdContent => {
      setProjectsES(parseProjectsES(mdContent));
    }).catch(error => {
      console.error("Error fetching markdown content:", error);
    });
  }, []);
  return ProjectsES;
};
export default ProjectsArrayES;