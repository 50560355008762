import { Divider, Stack, Text, Container, Box, HStack, Center } from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import ProfileArray from "./ProfileArray";
function Contact({
  color,
  language,
  onLanguageChange
}) {
  const profile = ProfileArray();
  const linkedin = () => {
    window.open(`${profile.linkedin}`, "_blank", "noreferrer,noopener");
  };
  const github = () => {
    window.open(`${profile.github}`, "_blank", "noreferrer,noopener");
  };
  const email = () => {
    window.open(`mailto:${profile.email}`, "_blank", "noreferrer,noopener");
  };
  const whatsapp = () => {
    window.open(`https://wa.me/${profile.phone}`, "_blank", "noreferrer,noopener");
  };
  const translations = {
    en: {
      contact: "Contact"
    },
    es: {
      contact: "Contacto"
    }
  };
  const selectedTranslations = translations[language];
  return <>
      <Container maxW={"3xl"} id="contact">
        <Stack as={Box} textAlign={"center"} spacing={{
        base: 8,
        md: 14
      }} pb={{
        base: 20,
        md: 36
      }}>
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                06
              </Text>
              <Text fontWeight={800}>{selectedTranslations.contact}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Center>
              <HStack pt={4} spacing={4}>
                <FaLinkedin onClick={linkedin} size={28} />
                <FaGithub onClick={github} size={28} />
                <FaEnvelope onClick={email} size={28} />
                <FaWhatsapp onClick={whatsapp} size={28} />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>;
}
export default Contact;