import { Divider, Stack, Text, Container, Box, HStack, Card, CardHeader, CardBody, CardFooter, Flex, Badge, Image, List, ListItem, ListIcon, Button, ButtonGroup, Center } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import StudiesArray from "./StudiesArray";
import StudiesArrayES from "./StudiesArrayES";
import TagsArray from "./TagsArray";
function Studies({
  color,
  language,
  onLanguageChange
}) {
  const studies = StudiesArray();
  const studiesES = StudiesArrayES();
  const options = TagsArray("StudiesTags");
  const optionsES = TagsArray("StudiesTagsES");
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (options.length > 0) {
      setSelected(language === "es" ? "Licenciatura" : "Degree");
    }
  }, [options, language]);
  const handleSelected = value => {
    setSelected(value);
  };
  const translations = {
    en: {
      studies: "Studies"
    },
    es: {
      studies: "Estudios"
    }
  };
  const selectedTranslations = translations[language];
  return <>
      <Container maxW={"3xl"} id="studies">
        <Stack as={Box} textAlign={"center"} spacing={{
        base: 8,
        md: 14
      }} pb={{
        base: 20,
        md: 36
      }}>
          <Stack align="center" direction="row" px={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                02
              </Text>
              <Text fontWeight={800}>{selectedTranslations.studies}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Center px={4}>
            <ButtonGroup variant="outline" display="grid" gridTemplateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)"
          }} gridGap={2}>
              {language === "es" ? optionsES.map(option => <Button colorScheme={selected === option.value ? `${color}` : "gray"} onClick={() => handleSelected(option.value)}>
                      {option.value}
                    </Button>) : options.map(option => <Button colorScheme={selected === option.value ? `${color}` : "gray"} onClick={() => handleSelected(option.value)}>
                      {option.value}
                    </Button>)}
            </ButtonGroup>
          </Center>
          <Stack px={4} spacing={4}>
            {language === "es" ? studiesES.filter(stu => stu.tags.includes(selected)).map(stu => <Fade bottom>
                      <Card key={stu.institution} size="sm">
                        <CardHeader>
                          <Flex justifyContent="space-between">
                            <HStack>
                              <Image src={stu.image} h={50} />
                              <Box px={2} align="left">
                                <Text fontWeight={600}>{stu.institution}</Text>
                                <Text>{stu.position}</Text>
                              </Box>
                            </HStack>
                            <Text px={2} fontWeight={300}>
                              {stu.duration}
                            </Text>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Flex>
                            <List align="left" spacing={3}>
                              {stu.listItems.map((item, index) => <ListItem key={index}>
                                  <ListIcon boxSize={6} as={ChevronRightIcon} color={`${color}.500`} />
                                  {item}
                                </ListItem>)}
                            </List>
                          </Flex>
                        </CardBody>
                        <CardFooter>
                          <HStack spacing={2}>
                            {stu.badges.map(badge => <Badge key={badge.name} colorScheme={badge.colorScheme}>
                                {badge.name}
                              </Badge>)}
                          </HStack>
                        </CardFooter>
                      </Card>
                    </Fade>) : studies.filter(stu => stu.tags.includes(selected)).map(stu => <Fade bottom>
                      <Card key={stu.institution} size="sm">
                        <CardHeader>
                          <Flex justifyContent="space-between">
                            <HStack>
                              <Image src={stu.image} h={50} />
                              <Box px={2} align="left">
                                <Text fontWeight={600}>{stu.institution}</Text>
                                <Text>{stu.position}</Text>
                              </Box>
                            </HStack>
                            <Text px={2} fontWeight={300}>
                              {stu.duration}
                            </Text>
                          </Flex>
                        </CardHeader>
                        <CardBody>
                          <Flex>
                            <List align="left" spacing={3}>
                              {stu.listItems.map((item, index) => <ListItem key={index}>
                                  <ListIcon boxSize={6} as={ChevronRightIcon} color={`${color}.500`} />
                                  {item}
                                </ListItem>)}
                            </List>
                          </Flex>
                        </CardBody>
                        <CardFooter>
                          <HStack spacing={2}>
                            {stu.badges.map(badge => <Badge key={badge.name} colorScheme={badge.colorScheme}>
                                {badge.name}
                              </Badge>)}
                          </HStack>
                        </CardFooter>
                      </Card>
                    </Fade>)}
          </Stack>
        </Stack>
      </Container>
    </>;
}
export default Studies;